$white: #fff !default;
$theme-colors: (
  "light":      #c7e0d6,
  "dark":       #103834,
  "primary":    #3b6a0a,
  "secondary":  #8dba1f,
  "info":       #aad400,
  "success":    #1ec100,
  "warning":    #ffca3b,
  "danger":     #ff0051,
) !default;
@import "bootstrap";
